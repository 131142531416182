:where(.css-dev-only-do-not-override-19nwipu).ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
:where(.css-dev-only-do-not-override-19nwipu).ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  display: none;
}

:where(.css-dev-only-do-not-override-19nwipu).ant-tabs-top > .ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-19nwipu).ant-tabs-bottom > .ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-19nwipu).ant-tabs-top > div > .ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-19nwipu).ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none;
}

.custom .ant-collapse-content-box {
  padding: 0;
}

.ant-tabs-nav-wrap {
  display: none !important;
}
