.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 56px;
  padding: 0;
  background: #673ab7;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 50;
}

.header h1 {
  float: left;
  margin: 0;
  padding: 0 15px;
  font-size: 24px;
  line-height: 56px;
  font-weight: 400;
  color: #fff;
}

.header nav {
  float: right;
  font-size: 100%;
}

.header nav a {
  display: inline-block;
  height: 56px;
  line-height: 56px;
  padding: 0 15px;
  min-width: 50px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  text-decoration: none;
  color: #fff;
  will-change: background-color;
}

.header nav a:hover,
.header nav a:active {
  background: rgba(0, 0, 0, 0.2);
}

.header nav a.active {
  background: rgba(0, 0, 0, 0.4);
}

.login-box {
  border-radius: 10px;
  display: flex;
  width: 100%;
  min-height: 50vh;
  place-items: center;
  margin: 2%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.item-right-topbar {
  margin-bottom: 15px !important;
}

.logo {
  margin: 10% 0;
  text-align-last: center;
}

.img-login {
  border-radius: 10px 0 0 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar {
  vertical-align: middle;
  height: auto;
  width: 60%;
  border-radius: 50%;
}

.avatar-profile {
  vertical-align: middle;
  height: auto;
  width: 4vh;
  border-radius: 50%;
  padding-left: 5px;
}

.profile-mini-box {
  cursor: pointer;
}

.img-login-box {
  width: 60vh !important;
  height: 100%;
}

.flex-login-box {
  display: flex;
  height: 100vh !important;
  width: 100%;
}

.breadcrumb-box {
  padding: 0 20px !important;
  padding-top: 20px !important;
}

.loading-center {
  max-height: 100vh !important;
  z-index: 2000 !important;
}

.div-loading-center {
  height: 100% !important;
  background-color: rgb(229, 229, 229, 0.5) !important;
}

.stripe-row-grey {
  background-color: rgb(229, 229, 229, 0.5);
}

.stripe-row-black {
  background-color: black;
}

.topbar-user {
  flex: 1;
  text-align-last: right;
  padding-right: 2px;
}

.topbar-dropdown {
  padding-top: 5px;
  padding-right: 3%;
}

.checkbox-group-vertical {
  display: block !important;
  margin-right: 0 !important;
}

.content-checkbox-group {
  display: block;
  max-height: 30vh !important;
  overflow-y: auto;
  margin-right: 16px !important;
}

.topbar-menu {
  place-self: center;
  padding-top: 15px;
  white-space: nowrap;
}

.header-topbar-menu-fixed {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 80%;
}

.header-topbar-logo {
  float: left;
  width: 120px !important;
  height: 31px !important;
  margin: 16px 24px 16px 0;
  background: red;
}

.topbar-menu-div {
  display: flex !important;
  width: 100% !important;
}

.topbar-profile-div {
  display: flex !important;
  width: 100% !important;
  flex: 1 !important;
}

.ant-menu-title-content {
  font-weight: bold;
}

@media only screen and (max-width: 680px) {
  .img-login-box,
  .item-right-topbar {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .topbar-menu {
    display: none;
  }
}

@font-face {
  font-family: 'Prompt';
  src: url('../assets//fonts/OTF/Prompt-Light.ttf') format('truetype');
  font-style: normal;
}

:where(.css-dev-only-do-not-override-19nwipu).ant-layout {
  font-family: 'Prompt', sans-serif !important;
}

:where(.css-dev-only-do-not-override-19nwipu).ant-typography {
  font-family: 'Prompt', sans-serif !important;
}

:where(.css-dev-only-do-not-override-1nqklau).ant-btn {
  font-family: 'Prompt', sans-serif !important;
}

:where(.css-dev-only-do-not-override-19nwipu).ant-input {
  font-family: 'Prompt', sans-serif !important;
}

:where(.css-dev-only-do-not-override-19nwipu).ant-select-single .ant-select-selector {
  font-family: 'Prompt', sans-serif !important;
}

:where(.css-dev-only-do-not-override-no4izc).ant-btn {
  font-family: 'Prompt', sans-serif !important;
}

:where(.css-dev-only-do-not-override-19nwipu).ant-col {
  font-family: 'Prompt', sans-serif !important;
}

:where(.css-dev-only-do-not-override-19nwipu).ant-checkbox-wrapper {
  font-family: 'Prompt', sans-serif !important;
}

:where(.css-dev-only-do-not-override-19nwipu).ant-upload-wrapper {
  font-family: 'Prompt', sans-serif !important;
}

:where(.css-dev-only-do-not-override-19nwipu).ant-upload-wrapper {
  font-family: 'Prompt', sans-serif !important;
}

:where(.css-dev-only-do-not-override-vcvfdv).ant-btn {
  font-family: 'Prompt', sans-serif !important;
}
