.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9f9;
  position: relative;
  padding-bottom: 6rem;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #1c1b1f;
}

.loader {
  animation: spin 1s linear infinite;
  border-bottom: 2px solid white;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.full-width-image {
  width: 100%;
  height: auto;
  display: block;
}

.details-section {
  background-color: white;
  padding: 1.5rem;
  color: #333;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.subtitle {
  color: #a9a9a9;
  margin-bottom: 0.5rem;
}

.expiry {
  color: #666;
  margin-top: 0.5rem;
}

.section-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.button-container {
  position: fixed;
  bottom: 5vh;
  left: 0;
  width: 100%;
  background-color: #f9f9f9;
  padding: 1rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  gap: 1rem;
}

.button {
  flex: 1;
  padding: 0.75rem;
  background-color: #3f51b5;
  color: white;
  border-radius: 0.5rem;
  font-weight: 500;
  text-align: center;
  transition: background-color 0.3s;
  cursor: pointer;
  border: none;
}

.button:hover {
  background-color: #3949ab;
}

.icon-calendar {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  vertical-align: middle;
}
