.qr-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.qr-overlay {
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
  border: 2px solid #1890ff;
  border-radius: 8px;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.1);
}

.qr-reader {
  width: 100% !important;
  height: auto !important;
}

.qr-reader section {
  padding: 0 !important;
}

.qr-reader video {
  object-fit: cover;
  width: 100% !important;
  height: auto !important;
}